import { getdemobyid } from '../../api/demo'
const state = {
  name: '',
  email: ''
}

// 所有的mutation 都是同步操作且必须式同步操作，可以通过commit信号触发,通常定义一些基础的数据修改
const mutations = {
  setName: (state, name) => {
    state.name = name
  },

  setEmail: (state, email) => {
    state.email = email
  }

}

// actions 可以包含任何异步操作 ，通过dispatch信号触发，通常可以实现比较复杂点的业务逻辑
const actions = {
  getdemobyidAction({ commit }, id) {
    getdemobyid(id).then(
      res => {
        commit('setName', res.data.name)
        commit('setEmail', res.data.email)
      }
    )
  }
}

// 模块内维护的getters
const getters = {
  getDemoinfo: (state, getters, rootState) => {
    return {
      name: state.name,
      emalil: state.email
    }
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
