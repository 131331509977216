<template>
  <div>
    <ul class="flex-common">
      <li v-for="(item, index) in crumbs" :key="index">
        <router-link :to="item.route">{{ item.text }}</router-link>
        <i />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    crumbs: {
      type: Array,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>

ul {
  padding: 10px 0;
  margin: 0;
  li {
  position: relative;
    margin-right: 25px;
    list-style: none;

    a {
      text-decoration: none;
      color: grey;
    }
    a:hover {
      color: rgb(235, 72, 63);
    }
  }
  i::before {
    content: '';
    position: absolute;
    right: 0;
    margin: 10px -15px 10px 8px;
    // width: 2px;
    height: 2px;
    border-right: 1px solid #666;
    box-shadow: -1px 1px #666,
                -2px 2px #666,
                -3px 3px #666,
                -4px 4px #666,
                -5px 5px #666,

                -1px -1px #666,
                -2px -2px #666,
                -3px -3px #666,
                -4px -4px #666,
                -5px -5px #666;
  }
  li:last-child {
    a {
      color: rgb(235, 72, 63);
    }
    i::before {
      display: none;
    }
  }
}
</style>
