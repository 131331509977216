import { loginApi, logoutApi, brokerApi } from '../../api/login'

const state = {
  userName: '',
  loginStatus: false,
  brokerInfoData: {}
}

const mutations = {
  changeLoginStatus: (state, login_status) => {
    state.loginStatus = login_status
  },
  changeUsername: (state, username) => {
    state.userName = username
  },
  storeBrokerInfo: (state, data) => {
    state.brokerInfoData = data
  }
}

const actions = {

  loginAction({ commit }, loginInfo) {
    const { username } = loginInfo
    return new Promise((resolve, reject) => {
      loginApi(loginInfo).then(res => {
        if (res.result === 'success') {
          commit('changeLoginStatus', res.login_status)
          commit('changeUsername', username)
        } else {
          alert('账号或者密码错误！')
        }
        resolve()
      }).catch(err => {
        console.log('err', err)
        reject(err)
      })
    })
  },

  logoutAction({ commit }) {
    return new Promise((resolve, reject) => {
      logoutApi().then(res => {
        if (res.result === 'success') {
          commit('changeLoginStatus', false)
          commit('changeUsername', '')
          sessionStorage.clear()
        }
        resolve()
      }).catch(err => {
        console.log('err', err)
        reject()
      })
    })
  },

  fetchBrokerInfo({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      brokerApi(userInfo).then(res => {
        if (res.data !== null) {
          commit('storeBrokerInfo', res.data)
        }
        resolve()
      }).catch(err => {
        console.log(err)
        reject()
      })
    })
  },

  removeBrokerInfo({ commit }) {
    commit('storeBrokerInfo', {})
  }
}

const getters = {
  loginStatus: state => state.loginStatus,
  userName: state => state.userName
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
