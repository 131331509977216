<template>
  <div class="layout">
    <!-- header -->
    <Header />
    <!-- header end -->

    <!-- main -->
    <div id="screenSize" class="layout-main" style="min-height:1080px">
      <!-- <transition name="fade"> -->
      <router-view />
      <!-- </transition> -->
    </div>
    <!-- main end -->

    <!-- footer -->
    <Footer />
    <!-- footer end -->

    <!-- float box -->
    <FloatBoxes />
    <!-- float box end -->
  </div>
</template>

<script>
import Header from '@/views/Header'
import Footer from '@/views/Footer'
import FloatBoxes from '@/components/FloatBoxes'
export default {
  name: 'Layout',
  components: {
    Header,
    Footer,
    FloatBoxes
  },

  mounted() {
    // window.onscroll = this.scroll;
    // this.getContainerHeight()
    // window.scrollTo(0,10)
  },

  methods: {
    getContainerHeight: function() {
      const screenHeight = document.getElementById('screenSize')
      const pageHeight = window.innerHeight
      screenHeight.style.minHeight = pageHeight + 10 + 'px'
    }
    // scroll:function(){
    //   console.log("检测到页面滚动事件:"+window.pageXOffset+" "+window.pageYOffset);
    //   if (window.pageYOffset !== 0) {
    //     console.log('this.$refs.nav:',this.$refs.nav);
    //     this.$refs.nav.setAttribute('class','nav-scroll');
    //   }
    // }
  }
}
</script>

<style lang="scss">
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
//公用flex布局class
.flex-common {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-scroll {
  background-color: grey;
}
</style>

