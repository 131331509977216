import request from '@/utils/http'

export function data_mail_titleApi() {
  return request({
    url: 'api/office_web/data_mail_title/',
    method: 'get'
  })
}
export function data_mail_contentApi(label) {
  return request({
    url: 'api/office_web/data_mail_content/',
    method: 'get',
    params: { label }
  })
}
export function dss_contentApi() {
  return request({
    url: 'api/office_web/dss_content/',
    method: 'get'
  })
}
export function get_listApi() {
  return request({
    url: 'api/office_web/get_list/',
    method: 'get'
  })
}
// 两个地址不同
export function strategy_chartApi(query) {
  return request({
    url: 'api/office_web/strategy_chart/',
    method: 'get',
    params: query
  })
}
export function new_transaction_recordApi(query) {
  return request({
    url: '/api/office_web/new_transaction_record/',
    method: 'get',
    params: query
  })
}
export function adjust_recordApi(query) {
  return request({
    url: '/api/office_web/adjust_record/',
    method: 'get',
    params: query
  })
}
export function get_category_groupApi(query) {
  return request({
    url: 'api/office_web/get_category_group/',
    method: 'get',
    params: query
  })
}
export function get_personal_infoApi() {
  return request({
    url: 'api/office_web/get_personal_info/',
    method: 'get'
  })
}
export function personal_transaction_recordlistApi() {
  return request({
    url: 'api/office_web/personal_transaction_record/',
    method: 'get'
  })
}
export function personal_transaction_recordtimetApi(value) {
  return request({
    url: 'api/office_web/personal_transaction_record/',
    method: 'get',
    params: { value }
  })
}
export function personal_adjust_recordlistApi() {
  return request({
    url: 'api/office_web/personal_transaction_record/',
    method: 'get'
  })
}
export function personal_adjust_recordtimetApi(value) {
  return request({
    url: 'api/office_web/personal_transaction_record/',
    method: 'get',
    params: { value }
  })
}
export function change_useApi(status) {
  return request({
    url: 'api/office_web/change_use/',
    method: 'get',
    params: { status }
  })
}
export function judge_trade_dayApi(value) {
  return request({
    url: '/api/office_web/judge_trade_day/',
    method: 'get',
    params: { value }
  })
}
// 获取用户信息
export function getUserInfofromNewServer(username) {
  const data = {
    username: username,
    nativeCode: 'imAinvestcnabc'
    // phoneNumber: phoneNumber
  }
  return request({
    url: '/v3/getuserinfobn',
    method: 'post',
    data
  })
}
