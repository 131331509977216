import {
  rankListApi
} from '../../api/rank'
const state = {
  ranklist: []

}

// 所有的mutation 都是同步操作且必须式同步操作，可以通过commit信号触发,通常定义一些基础的数据修改
const mutations = {

  setRankList: (state, list) => {
    state.ranklist = list
  }

}

// actions 可以包含任何异步操作 ，通过dispatch信号触发，通常可以实现比较复杂点的业务逻辑
const actions = {

  fetchRanklist({ commit }) {
    return new Promise((resolve, reject) => {
      rankListApi().then((res) => {
        commit('setRankList', res.data.data[0])
        resolve()
      }).catch(err => {
        reject(err)
      })
    })
  }
}

// 模块内维护的getters
const getters = {
  getRanklist: state => {
    return state.ranklist
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
