<template>
  <div class="fixed-container">
    <!-- <div class="box-item flex-center-column" @click="dialogVisible=true">
      <img src="@/assets/img/daili.png" alt="" style="width:29px;height:29px;display:block">
    </div> -->
    <div class="line" />
    <div v-for="(box, index) in boxItems" :key="index">
      <div class="box-item flex-center-column">
        <img :src="box.iconUrl" alt="d" srcset>
        <div>{{ box.text }}</div>
        <div v-for="(childrenBox, i) in box.childrenContent" :key="i" class="children-content">
          <img v-if="childrenBox.childImgUrl" :src="childrenBox.childImgUrl" alt="">
          <div v-if="childrenBox.childrenText">{{ childrenBox.childrenText }}</div>
        </div>
      </div>
      <div class="line" :class="'line'+index" />
    </div>
    <div class="box-item flex-center-column" @click="backToTop">
      <img src="https://static.ainvestcn.com/icon/go-top.png" alt="">
      <div>TOP</div>
    </div>
    <el-dialog
      title="诚招合作伙伴"
      :visible.sync="dialogVisible"
      width="20%"
    >
      <span><img class="come" src="@/assets/img/comeon.png" alt=""></span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">微信扫一扫加入我们</el-button>
      </span>
    </el-dialog>

    <!-- <div class="box-item flex-center-column" @click="goTop">
      <img src="https://static.ainvestcn.com/icon/go-top.png" alt="d" srcset>
      <div>TOP</div>
    </div> -->
  </div>
</template>

<script>
import '@/assets/style/common.scss'
export default {
  data() {
    return {
      dialogVisible: false,
      boxItems: [
        {
          iconUrl: 'https://static.ainvestcn.com/icon/contact.png',
          text: '',
          childrenContent: [
            {
              childImgUrl: 'https://static.ainvestcn.com/xiaoqi_20191112150637.jpg',
              childrenText: '客服：小柒'
            }
          ]
        },
        {
          iconUrl: 'https://static.ainvestcn.com/icon/weixin-s.png',
          text: '',
          childrenContent: [
            {
              childImgUrl: 'https://static.ainvestcn.com/big_img/Wechat-person.jpeg',
              childrenText: 'CEO雷总'
            }
          ]
        }
        // {
        //   iconUrl: 'https://static.ainvestcn.com/icon/go-top.png',
        //   text: 'TOP',
        //   func: 'goTop',
        //   childrenContent: []
        // }
      ]
    }
  },

  methods: {
    backToTop: function() {
      console.log('in gotop')
      const dom = document.body.scrollTop ? document.body : document.documentElement
      let scrollTop = dom.scrollTop
      const goTop = function() {
        console.log('in goTop')
        scrollTop = scrollTop + (0 - scrollTop) / 10
        if (scrollTop < 1) {
          dom.scrollTop = 0
          return
        }
        dom.scrollTop = scrollTop
        requestAnimationFrame(goTop)
      }
      goTop()
    }
  }

}
</script>

<style lang="scss" scoped>
.fixed-container {
  position: fixed;
  right: 0;
  bottom: 100px;
  background-color: #fff;
  z-index: 1000;
  box-shadow: 0 2px 4px 1px rgba(40, 120, 255, 0.08), 0 0 6px 1px rgba(0, 0, 0, 0.08);
}

.box-item {
  position: relative;
  width: 60px;
  height: 60px;
  padding: 0 10px;

  .children-content {
    // width: 0;
    display: none;
  }
}

.box-item:hover{
  background:rgba(0,0,0,0.1);
  cursor: pointer;

  .children-content {
    display: block;
    // width: 200px;
    transition: 3s;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px 1px rgba(40, 120, 255, 0.08), 0 0 6px 1px rgba(0, 0, 0, 0.08);
    position: absolute;
    left: -200px;
    top: 0;
    z-index: 1000;

    img {
      width: 100px;
    }
  }
}

.line {
  // height: 1px;
  // width: 100%;
  margin: 0 10px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(172,182,211,1);
}

.line2 {
  border-bottom: 0;
}
.come{
  width: 100%;
}
</style>
<style>

.v-modal{
  z-index: 0 !important;
}
.el-dialog__title {
    color: #0abfcb !important;
}
</style>
