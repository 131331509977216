<template>
  <div class="header" :class="{scroll: isScroll }">
    <div class="flex-between">
      <img src="@/assets/img/logo_half.png" alt>
      <ul class="flex-between">
        <li v-for="(item, index) in menus" :key="index" :class="currentRoute === item.name ? 'active' : ''" @click="changeNavStatus(item.name)">
          <!-- <a href="#" @click="goto(item.route)">{{item.name}}</a> -->
          <router-link :to="item.childMenu.length !== 0 ? '' : item.route">{{ item.name }}</router-link>
          <i v-if="item.childMenu.length !== 0" class="icon-up-down icon-down" />
          <!-- <i v-if="item.childMenu.length !== 0" class="icon-up-down icon-down-hover" /> -->
          <ul v-if="item.childMenu.length !== 0" class="children-menu">
            <li v-for="(chlidItem, i) in item.childMenu" :key="i">
              <a class="hander" @click="goto(chlidItem.route)">{{ chlidItem.name }}</a>
            </li>
          </ul>
        </li>
      </ul>
      <div class="flex-center">
        <a href="https://fingpt.ainvestcn.com/login?inviteCode=OfficialWebsite" target="_blank">
          <button class="buy mr-13">
            <img src="@/assets/img/1-b@2x.png" alt="buy"> 金声玉亮
          </button>
        </a>
        <!-- <div class="login flex-center" style="position:relative" @click="login">
          <img v-if="loginStatus" src="@/assets/img/2@2x.png" alt="buy">
          {{ loginStatus ? userName : '登录' }}
          <i
            v-if="loginStatus"
            class="icon-up-down icon-down icon-mt-0"
          />
          <div v-if="loginStatus" class="vip">
            <img v-if="isvip" src="@/assets/img/vip3.png" alt="">
            <img v-else src="@/assets/img/novip.png" alt="">
          </div>
          <ul v-if="loginStatus" class="children-menu" style="top:40px;left:0;">
            <li v-if="!isvip">
              <router-link to="/pay">成为VIP</router-link>
            </li>

            <li>
              <router-link to="/user-account">个人账户</router-link>
            </li>

            <li>
              <router-link to="/broker">券商信息</router-link>
            </li>
            <li style="margin-bottom:10px" @click="logout"><a>登出</a></li>
          </ul>
        </div> -->
        <!-- <div v-if="loginStatus" class="exptime">
          <span v-if="!isvip&&exptime>0" style="color:red">试用结束</span>
          <span v-if="!isvip&&exptime===0" style="color:red">您已试用5天，明天将暂停自动交易和选策略功能</span>
          <span v-if="!isvip&&exptime<0" style="color:red">{{ -exptime }}天后试用结束</span>
          <span v-if="isvip"> 到期时间 {{ stopTime }} </span>
        </div>
        <div v-if="!loginStatus" class="login" style="padding:0">|</div> -->
        <!-- <div v-if="!loginStatus" class="login" @click="goto('/register')">
          注册
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { vipApi } from '../api/login'
// import { setTimeout } from 'timers'
import { getUserInfofromNewServer } from '../api/api'
export default {
  data() {
    return {
      uinfo2: {
        regTime: '',
        vipStoptime: ''
      },
      exptime: '',
      isvip: false,
      isScroll: true,
      // currentRouteName: '首页',
      trading: true,
      menus: [
        {
          name: '首页',
          route: '/#home',
          childMenu: []
        },
        // {
        //   name: '产品',
        //   route: '',
        //   childMenu: [
        //     {
        //       name: '一刀智投',
        //       route: '/user-document'
        //     },
        //     {
        //       name: '策略商城',
        //       route: '/strategy-list'
        //     },
        //     {
        //       name: '策略排行榜',
        //       route: '/leaderboard'
        //     }
        //   ]
        // },
        {
          name: '信息资讯',
          route: '/news',
          childMenu: []
        },
        // {
        //   name: '产品优势',
        //   route: '/#advantage',
        //   childMenu: []
        // },
        // {
        //   name: '一刀智投',
        //   route: '',
        //   childMenu: [
        //     {
        //       name: '自动交易',
        //       route: '/user-document'
        //     },
        //     {
        //       name: '策略排行榜',
        //       route: '/leaderboard'
        //     }
        //   ]
        // },
        {
          name: '公司历程',
          route: '/#growth',
          childMenu: []
        },
        // {
        //   name: '案例介绍',
        //   route: '/#case',
        //   childMenu: []
        // },
        {
          name: '合作伙伴',
          route: '/#partner',
          childMenu: []
        }
      ],
      logined: false,
      username: ''
    }
  },

  computed: {
    stopTime() {
      if (this.uinfo2.vipStoptime) {
        return this.uinfo2.vipStoptime.slice(0, 10)
      }
      return '正在获取'
    },
    ...mapState({
      loginStatus: state => state.login.loginStatus,
      userName: state => state.login.userName,
      currentRoute: state => state.nav.currentRoute,
      brokerInfoData: state => state.login.brokerInfoData
    })
  },

  mounted() {
    // window.onscroll = this.scroll
    // // this.navMouseover()
    // this.userHover()
    this.curUserInfo()
    this.vip()
    this.init()
  },
  updated() {
    this.curUserInfo()
  },

  methods: {

    isvipFun() {

    },

    ...mapActions({
      changeNavActive: 'nav/changeNavActive',
      logoutAction: 'login/logoutAction',
      fetchBrokerData: 'login/fetchBrokerData',
      removeBrokerInfo: 'login/removeBrokerInfo'
    }),
    async init() {
      const username = localStorage.getItem('username')
      const uinfo2 = await getUserInfofromNewServer(username)
      this.uinfo2.vipStoptime = uinfo2.data.vipStopTime
      const now = new Date()
      // console.log(now)
      const regtime = new Date(uinfo2.data.regTime)
      // console.log(regtime)
      const exptime = (now - regtime - 5 * 24 * 3600000) / 24 / 3600000
      this.exptime = parseInt(exptime)
      // console.log(this.exptime)
      localStorage.setItem('exptime', this.exptime)
    },
    async curUserInfo() {
      const username = await sessionStorage.getItem('session_username')
      const loginStatus = await sessionStorage.getItem('session_loginStatus')
      this.logined = loginStatus
      this.username = username
    },
    goto: function(path) {
      // console.log('path', path)
      this.$router.push(path)
    },
    // 头部样式
    // scroll: function() {
    //   if (window.pageYOffset !== 0) {
    //     this.isScroll = true
    //   }
    //   if (
    //     window.pageYOffset === 0 &&
    //     this.$router.currentRoute.path === '/'
    //   ) {
    //     this.isScroll = false
    //   }
    //   if (
    //     window.pageYOffset !== 0 &&
    //     this.$router.currentRoute.path === '/'
    //   ) {
    //     this.isScroll = true
    //   }
    // },

    login: function() {
      if (!this.loginStatus) {
        this.$router.push('/login')
      } else {
        return
      }
    },

    // async vip() {
    //   const username = localStorage.getItem('username')
    //   console.log(username)
    //   const rsltRep = await vipApi(username)
    //   console.log(rsltRep)
    //   if (rsltRep.data.isvip === '1') {
    //     this.isvip = true
    //   } else {
    //     this.isvip = false
    //   }
    // },

    vip: function vip() {
      const username = localStorage.getItem('username')
      vipApi(username).then((res) => {
        localStorage.setItem('isvip', res.data.isvip)
        if (res.data.isvip === '1') {
          this.isvip = true
        }
      })
    },
    // logout
    logout: function logout() {
      localStorage.clear()
      sessionStorage.clear()
      this.logoutAction().then(() => {
        // console.log('store loginStatus', this.loginStatus)

        this.removeBrokerInfo()
        // 清空登陆信息
        localStorage.clear()
        sessionStorage.clear()
        console.log('removeBrokerData', this.brokerInfoData)
        this.$router.push('/')
        // window.location.href = '/'
      })
    },

    // change navitem active
    changeNavStatus: function(navName) {
      this.changeNavActive(navName)
      // console.log('currentRoute', this.currentRoute)
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  color: #FFF;
  padding: 14px 100px;
  position: fixed;
  top: 0;
  z-index: 1000;
  .icon-down:before {
        border-left: 1px solid #fff;
        box-shadow: 1px -1px #fff, 2px -2px #fff, 3px -3px #fff,
          4px -4px #fff, 5px -5px #fff, -1px -1px #fff,
          -2px -2px #fff, -3px -3px #fff, -4px -4px #fff,
          -5px -5px #fff;
      }

  img {
    width: 148px;
    height: 34px;
  }

  ul {
    margin: 0;
    padding: 0 40px;
    width: 60%;

    li {
      list-style: none;
      // padding: 0 40px;
      position: relative;

      a {
        color: #fff;
        text-decoration: none;
        display: inline-block;
      }
    }

    li:hover {
      // color: rgb(235, 72, 63);
       a {
        color: rgb(235, 72, 63);
      }
      .children-menu {
        height: 153px;
      }
      .icon-down:before {
        border-left: 1px solid #ec3c2e;
        box-shadow: 1px -1px #ec3c2e, 2px -2px #ec3c2e, 3px -3px #ec3c2e,
          4px -4px #ec3c2e, 5px -5px                                                            #ec3c2e, -1px -1px #ec3c2e,
          -2px -2px #ec3c2e, -3px -3px #ec3c2e, -4px -4px #ec3c2e,
          -5px -5px #ec3c2e;
      }
    }
  }

  .active {
    a {
    color: rgb(235, 72, 63)
    }
    .icon-down:before {
        border-left: 1px solid #ec3c2e;
        box-shadow: 1px -1px #ec3c2e, 2px -2px #ec3c2e, 3px -3px #ec3c2e,
          4px -4px #ec3c2e, 5px -5px #ec3c2e, -1px -1px #ec3c2e,
          -2px -2px #ec3c2e, -3px -3px #ec3c2e, -4px -4px #ec3c2e,
          -5px -5px #ec3c2e;
      }
  }
  .hander {
    cursor: pointer;
  }
  //children menu
  .children-menu {
    height: 0;
    overflow: hidden;
    width: 160px;
    position: absolute;
    top: 25px;
    transition: 0.6s;
    background-color: #fff;
    li {
      margin-top: 20px;
      a {
        color: #666;
      }
    }
    // opacity: 0.9;
    // border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.1);
  }
  button {
    border: 0;
    // width: 108px;
    padding: 0 20px;
    height: 36px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    color: #666;

    img {
      width: 13px;
      height: 13px;
    }
  }

  button.buy {
    background: rgba(236, 60, 46, 1);
    color: #fff;
  }

  div.login {
    cursor: pointer;
    img {
      margin-right: 7px;
      width: 13px;
      height: 13px;
    }
    padding: 0 10px;
  }
  div.vip{
    cursor: pointer;
    img{
      margin-left: -5px;
      width: 40px;
      height: 40px;
    }
  }

  div.login:hover {
    .children-menu {
      height: auto

    }
    color: #ec3c2e;
    .icon-down:before {
        border-left: 1px solid #ec3c2e;
        box-shadow: 1px -1px #ec3c2e, 2px -2px #ec3c2e, 3px -3px #ec3c2e,
          4px -4px #ec3c2e, 5px -5px #ec3c2e, -1px -1px #ec3c2e,
          -2px -2px #ec3c2e, -3px -3px #ec3c2e, -4px -4px #ec3c2e,
          -5px -5px #ec3c2e;
      }
  }
}

//login_status
.login-success {
  img {
    width: 20px;
    height: 20px;
  }
}

//stopbutton
.green {
  background-color: green;
}

//滚动后nav的状态
.scroll {
  // background-color: #fff;
  // color: #666;
  // opacity: 1;
  // box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
   background-color: rgba(19, 22, 39,0.8);
  //  color: #666;
   box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);

  .icon-down:before {
        border-left: 1px solid #666;
        box-shadow: 1px -1px #666, 2px -2px #666, 3px -3px #666,
          4px -4px #666, 5px -5px                                                            #666, -1px -1px #666,
          -2px -2px #666, -3px -3px #666, -4px -4px #666,
          -5px -5px #666;
      }

  ul > li > a {
    color: white;
  }
  li:hover {
    color: rgb(235, 72, 63);
    > a {
      color: rgb(235, 72, 63);
    }
  }
}
.mr-13 {
  margin-right: 13px;
}
.exptime{
   width: 180px;
   margin-right: -90px;
}

//box-shadow make icon
.icon-up-down {
  display: inline-block;
  width: 20px;
  height: 20px;
}
:root .icon-up {
  background-image: none;
}
.icon-down::before {
  content: '';
  position: absolute;
  margin: 16px 0 0 8px;
  height: 2px;
  border-left: 1px solid #666;
  box-shadow: 1px -1px #666, 2px -2px #666, 3px -3px #666, 4px -4px #666,
    5px -5px #666, -1px -1px #666, -2px -2px #666, -3px -3px #666,
    -4px -4px #666, -5px -5px #666;
}
.icon-mt-0::before {
  margin-top: 11px;
}
</style>
