import { loginApi } from '../../api/user'

const state = {
  username: '',
  login_state: '',
  token: ''
}// 声明 user模型的字段 ，可以理解为定义数据模型
const mutations = {
  setUsername: (state, username) => {
    state.username = username
  },
  setLogin_state: (state, login_state) => {
    state.login_state = login_state
  },
  setToken: (state, token) => {
    state.token = token
  }

}// 可以理解为 setter 修改 state中的值
const actions = {
  login({ commit }, userinfo) {
    const { username } = userinfo
    return new Promise((resolve, reject) => {
      loginApi(userinfo).then(res => {
        if (res.login_status) {
          commit('setUsername', username)
          commit('setLogin_state', '1')
          sessionStorage.setItem('login_state', '1')
          // commit('setToken', res.data.token)
          console.log('login success')
        } else {
          console.log('登陆失败')
        }

        resolve()
      }).catch(err => {
        console.log(err)
        reject(err)
      })
    })
  }
  // fetchuserInfo({ commit, state }, token) {
  //   return new Promise((resolve, reject) => {
  //     // 调用写好的 api function
  //     getuserinfo().then().catch()
  //   })
  // }// 返回一个获取结果的promise
}// 功能和mutations 类似，
// 但是它不能直接操作state 而是通过提交一个mutation 来触发值的修改
// const getters = {
//   token: state => state.user.token
// }
export default {
  namespaced: true,
  state,
  mutations,
  actions
  // getters
}
