import { get, post } from 'http'

export function getdemobyid(id) {
  const url = 'v2/demo/?id=' + id
  return get(url)
}

export function getdemolist() {
  const url = 'v2/demo/listall'
  return get(url)
}// get all items

/**
 * 分页数据
 * @param {number} pagesize 页内数据量
 * @param {number} pagenum 页码
 * @author rddapi@foxmail.com
 * @date 2019/08/07 10:59
 */
export function getdemopaged(pagesize, pagenum) {
  const url = 'v2/demo/list?size=' + pagesize + '&num=' + pagenum
  return get(url)
}

export function editDemo(name, email) {
  const url = 'v2/demo/edit'
  const obj = {
    name: name,
    email: email
  }
  return post(url, obj)
}
