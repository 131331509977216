import request from '@/utils/http'

export function loginApi(loginInfo) {
  const url = 'api/ainvest_login/'
  return request({
    url: url,
    method: 'post',
    data: loginInfo
  })
}

export function logoutApi() {
  const url = 'api/ainvest_logout/'
  return request({
    url: url,
    method: 'post'
  })
}

export function brokerApi(userInfo) {
  const url = '/v3/getuserinfobn'
  return request({
    url,
    method: 'post',
    data: userInfo
  })
}
/**
 * 判断是否vip
 * @param {string } username 用户名
 */
// export function vipApi(username) {
//   // 封请求体
//   const data = {
//     nativeCode: 'imAinvestcnabc',
//     username: username
//   }
//   const url = '/v3/isvip'
//   return request({
//     url,
//     method: 'post',
//     data: data
//   })
// }

export function vipApi(username) {
  const data = {
    nativeCode: 'imAinvestcnabc',
    username: username
  }
  const url = '/v3/isvip'
  return request({
    url,
    method: 'post',
    data: data
  })
}

export function isloginApi() {
  const url = '/api/office_web/judge_login/'
  return request({
    url,
    method: 'get'
  })
}

