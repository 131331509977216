import request from '@/utils/http'
// export function getuserinfo(token) {
//   const url = 'api/getuserinfo?' + token
//   return get(url)
// }// 假设接口需要accesstoken的

export function loginApi(userInfo) {
  const url = 'api/ainvest_login/'
  return request({
    url: url,
    method: 'post',
    data: userInfo
  })
}

export function isvipApi(username) {
  const data = {
    nativeCode: 'imAinvestcnabc',
    username: username
  }
  return request({
    url: '/v3/isvip',
    method: 'post',
    data
  })
}

