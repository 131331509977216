const state = {
  currentRoute: '首页'
}

const mutations = {
  toggleNavActive: (state, path) => {
    state.currentRoute = path
  }
}

const actions = {
  changeNavActive({ commit }, path) {
    commit('toggleNavActive', path)
    // console.log('in changeNavActive', path)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
