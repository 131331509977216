import axios from 'axios'

axios.interceptors.request.use((config) => {
  // config.headers['X-Requested-With'] = 'XMLHttpRequest';
  config.withCredentials = true
  config.xsrfCookieName = 'csrftoken'
  config.xsrfHeaderName = 'X-CSRFToken'
  const regex = /.*csrftoken=([^;.]*).*$/ // 用于从cookie中匹配 csrftoken值
  config.headers['X-CSRFToken'] = document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1]
  return config
})

const http = {
  get: '',
  post: ''
}

http.get = function(api, data) {
  return new Promise((resolve, reject) => {
    axios.get(api, data).then(
      (res) => {
        resolve(res)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

http.post = function(api, data) {
  return new Promise((resolve, reject) => {
    axios.post(api, data).then(
      (res) => {
        resolve(res)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

export default http
