import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Layout from '@/components/Layout'
export const constantRoutes = [{
  path: '/',
  name: 'Layout',
  component: Layout,
  redirect: '/',
  children: [
    {
      path: '/',
      name: 'home',
      metaname: 'homepage',
      component: () => import('@/views/Index')
    },
    {
      path: '/strategy-list',
      name: 'strategyList',
      component: () => import('@/views/strategy/strategy-list')
    },
    {
      path: '/strategy-detail/:categoryId/:strategyId',
      name: 'strategyDetail',
      metaname: '',
      component: () => import('@/views/strategy/strategy-detail')
    },
    {
      path: '/data-list',
      name: 'data-list',
      metaname: '',
      component: () => import('@/views/dataMarket/data-list')
    },
    {
      path: '/dss',
      name: 'dss',
      metaname: '',
      component: () => import('@/views/dss/dss')
    },
    {
      path: '/step-1',
      name: 'step-1',
      metaname: '',
      component: () => import('@/views/dss/step-1')
    },
    {
      path: '/step-2',
      name: 'step-2',
      metaname: '',
      component: () => import('@/views/dss/step-2')
    },
    {
      path: '/pay',
      name: 'pay',
      component: () => import('@/views/pay/index.vue')
    },
    {
      path: '/user-account',
      name: 'user-account',
      metaname: '',
      component: () => import('@/views/user/user-account.vue')
    },
    {
      path: '/user-document',
      name: 'user-document',
      metaname: '',
      component: () => import('@/views/autoTrading/user-document.vue')
    },
    {
      path: '/leaderboard',
      name: 'leaderboard',
      metaname: '',
      component: () => import('@/views/strategy/leaderboard.vue')
    },
    {
      path: '/broker',
      name: 'addBroker',
      metaname: '',
      component: () => import('@/components/baseComponents/base-broker.vue')
    },
    {
      path: '/news',
      name: 'news',
      metaname: '',
      component: () => import('@/components/publicPage/developing.vue')
    },
    {
      path: '/newone',
      name: 'newone',
      metaname: '',
      component: () => import('@/components/publicPage/news/one.vue')
    },
    {
      path: '/newtwo',
      name: 'newtwo',
      metaname: '',
      component: () => import('@/components/publicPage/news/two.vue')
    },
    {
      path: '/newthree',
      name: 'newthree',
      metaname: '',
      component: () => import('@/components/publicPage/news/three.vue')
    },
    {
      path: '/newfour',
      name: 'newfour',
      metaname: '',
      component: () => import('@/components/publicPage/news/four.vue')
    },
    {
      path: '/newfive',
      name: 'newfive',
      metaname: '',
      component: () => import('@/components/publicPage/news/five.vue')
    },
    {
      path: '/newsix',
      name: 'newsix',
      metaname: '',
      component: () => import('@/components/publicPage/news/six.vue')
    },
    {
      path: '/newseven',
      name: 'newseven',
      metaname: '',
      component: () => import('@/components/publicPage/news/seven.vue')
    },
    {
      path: '/neweight',
      name: 'neweight',
      metaname: '',
      component: () => import('@/components/publicPage/news/eight.vue')
    },
    {
      path: '/newnine',
      name: 'newnine',
      metaname: '',
      component: () => import('@/components/publicPage/news/nine.vue')
    }
  ]
},
{
  path: '/login',
  name: 'login',
  component: () => import('@/views/login')
},
{
  path: '/register',
  name: 'register',
  component: () => import('@/views/login/register.vue')
},
{
  path: '/test',
  name: 'reer',
  component: () => import('@/views/strategy/test.vue')
}

]

const createRouter = () => new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      if (to.hash) {
        console.log('in gotop')

        const dom = document.body.scrollTop ? document.body : document.documentElement
        const anchor = document.getElementById(to.hash.replace('#', ''))
        let scrollTop = dom.scrollTop

        const goTop = function() {
          scrollTop = scrollTop + (anchor.offsetTop - scrollTop) / 5
          if (Math.abs(anchor.offsetTop - scrollTop) < 1) {
            scrollTop = anchor.offsetTop
            return
          }
          dom.scrollTop = scrollTop
          requestAnimationFrame(goTop)
        }
        goTop()
        return false
      }
      // if (/^#\d/.test(to.hash) || document.querySelector(to.hash)) {
      //   return position
      // }
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ x: 0, y: 0 })
        }, 500)
      })
    }
  },
  routes: constantRoutes
})

const router = createRouter()
export default router
