import axios from 'axios'
// import { Store } from 'vuex'

const service = axios.create({
  baseURL: 'https://www.ainvestcn.com/',
  withCredentials: true,
  timeout: 5000

})
service.interceptors.request.use(
  config => {
    // if (Store.getters.token) {
    //   config.headers['X-Token'] = 'default-token'
    // }
    config.withCredentials = true
    config.xsrfCookieName = 'csrftoken'
    config.xsrfHeaderName = 'X-CSRFToken'
    const regex = /.*csrftoken=([^;.]*).*$/ // 用于从cookie中匹配 csrftoken值
    config.headers['X-CSRFToken'] = document.cookie.match(regex) === null ? null : document.cookie.match(regex)[1]
    return config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(response => {
  const res = response.data
  // if (res.code !== 20000 || res.code !== 200) {
  //   return Promise.reject(new Error(res.msg || 'Error'))
  // } else {
  //   return res
  // }
  return res
}, error => {
  return Promise.reject(error)
})

export default service
