<template>
  <div class="bv-footer-row">
    <!-- <b-row>
      <b-col class="contact-us">
        <h1>联系我们</h1>
        <p>CONTACT US</p>
        <div class="flex-common">
          <img class="icon" src="@/assets/img/mail@2x.png" alt="email">
          <p>email: contact@ainvest.io</p>
        </div>
        <div class="flex-common">
          <img class="icon" src="@/assets/img/weixin@2x.png" alt="email">
          <p>微信: JRLeiYidao</p>
        </div>
        <div class="flex-common">
          <div class="mr-20">
            <p>关注公众号</p>
            <img class="QR-code" src="@/assets/img/QR-code.4b1e8bda@2x.png" alt="email">
          </div>
          <div>
            <p>联系创始人</p>
            <img class="QR-code" src="@/assets/img/Wechat-person.jpeg" alt="email">
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="leave-message">
          <h1 class="text-center">留言建议</h1>
          <b-form @submit="onSubmit">
            <b-form-group label="邮件" lable-for="input-1" description="我们不会泄露您电子邮件">
              <b-form-input v-model="form.email" type="email" required placeholder="输入邮箱" />
            </b-form-group>
            <b-form-group label="姓名" label-for="input-2">
              <b-form-input v-model="form.name" placeholder="输入姓名" required />
            </b-form-group>
            <b-form-group label="留言">
              <b-form-textarea v-model="form.leave_word" required />
            </b-form-group>
            <b-button type="submit" class="submit-button">提交</b-button>
          </b-form>
        </div>
      </b-col>
    </b-row> -->
    <div class="power-by text-center">
      <a href="https://beian.miit.gov.cn/" target="_blank" style="text-decoration:none;color: #999999">京ICP备19005789号-1</a> |
      Powered by AInvest
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '',
        name: '',
        leave_word: '',
        require_type: 1
      }
    }
  },

  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      // alert(JSON.stringify(this.form));
      const objdata = {
        username: this.form.name,
        email: this.form.email,
        leave_word: this.form.leave_word,
        require_type: 1
      }
      this.http
        .post('http://39.105.211.99:8990/office_web/requirement_data/', objdata)
        .then(res => {
          if (res.status === 200) {
            this.$bvModal.msgBoxOk(
              '感谢您的留言，我们将在24小时之内跟您联系！',
              {
                title: '提交成功',
                size: 'sm',
                buttonSize: 'sm',
                okVariant: 'sucess',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              }
            ).then(value => {
              if (value) {
                this.onReset()
              }
            })
          }
        })
    },

    onReset(evt) {
      // evt.preventDefault()
      // Reset our form values
      this.form.email = ''
      this.form.name = ''
      this.form.leave_word = ''
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bv-footer-row {
  z-index: 1200;
  padding: 0 100px;
  background: rgba(10, 13, 24, 1);
  h1 {
    font-size: 28px;
    font-family: FZLTXHK;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
  }
  p {
    font-size: 14px;
    font-family: FZLTXHK;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
  }
}

//contact us
.contact-us {
  padding-top: 44px;
  padding-bottom: 34px;
}

.flex-common {
  img {
    margin-right: 10px;
  }
  img.icon {
    width: 24px;
    height: 17px;
  }
  img.QR-code {
    width: 177px;
    height: 177px;
  }
}

.mr-20 {
  margin-right: 20px;
}

//留言

.leave-message {
  width: 100%;
  height: 100%;
  background: rgba(2, 5, 17, 1);
  border-radius: 4px;
  padding-top: 43px;
  padding-left: 54px;
  padding-right: 54px;

  // .label {
  font-size: 14px;
  font-family: FZLTXHK;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  // }

  .submit-button {
    border: 0;
    width: 160px;
    height: 40px;
    background: rgba(255, 51, 51, 1);
    border-radius: 4px;
  }

  .submit-button:hover{
    opacity: 0.8;
  }
}

.power-by {
  border-top: 2px solid rgba(229, 229, 229, 0.14);
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 14px;
  font-family: FZLTXHK;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);

}
</style>
