<template>
  <div id="screenSize1" class="screen-size flex-center-column">
    <div class="login-form c-border">
      <div class="flex-center-column">
        <h4 class="">添加券商信息</h4>
      </div>
      <!-- <b-form> -->

      <div>
        <b-form-group id="input-group-1" class="mt-60" :class="{'input-group-error':$v.phoneNumber.$error}">
          <b-form-input id="input-1" v-model="$v.phoneNumber.$model" value="666" class="input-error" type="text" required placeholder="使用已注册的手机号" />
          <div v-if="!$v.phoneNumber.required" class="error">请填写手机号</div>
          <div v-if="!$v.phoneNumber.isPhoneNumber" class="error"> 请填写正确手机号</div>
          <div v-if="isRegistered" class="my-error"> 手机号已被注册</div>
        </b-form-group>
        <b-form-group id="input-group-6" class="mt-30" :class="{'input-group-error':$v.borker.$error}">
          <b-form-select id="input-6" v-model="$v.borker.$model" class="input-error" :options="borkers" value-field="id" text-field="name" value="sdsf" placeholder="使用券商(必选)" />
          <div v-if="!$v.borker.required" class="error">请选择您使用的券商</div>
        </b-form-group>
        <b-form-group id="input-group-7" class="mt-30" :class="{'input-group-error':$v.borkerAccount.$error}">
          <b-form-input id="input-7" v-model="$v.borkerAccount.$model" class="input-error" required placeholder="券商账号" />
          <div v-if="!$v.borkerAccount.required" class="error"> 请输入券商账号</div>
        </b-form-group>

        <b-form-group id="input-group-9" class="mt-30" :class="{'input-group-error':$v.tradePassword.$error}">
          <b-form-input
            id="input-9"
            v-model="$v.tradePassword.$model"
            class="input-error"
            type="password"
            required
            placeholder="交易密码"
          />
          <div v-if="!$v.tradePassword.required" class="error"> 请输入交易密码</div>
        </b-form-group>

        <b-form-group id="input-group-8" class="mt-30" :class="{'input-group-error':$v.borkerPassword.$error}">
          <b-form-input
            id="input-8"
            v-model="$v.borkerPassword.$model"
            type="password"
            class="input-error"
            required
            placeholder="通讯密码(选填)"
          />
        </b-form-group>

        <div v-if="brokerInfoData.securities" class="flex-center-column mt-60">
          <button @click="pullBrokerInfo(phoneNumber, username, borker, password, borkerAccount, borkerPassword, tradePassword)">修改</button>
        </div>
        <div v-if="brokerInfoData.securities" class="flex-center-column mt-60">
          <button @click="pullBrokerInfo(phoneNumber, username, borker, password, borkerAccount, borkerPassword, tradePassword)">提交</button>
          <div style="color:#666; padding:5px 0;">或试用</div>
          <button class="second">一键生成模拟账户</button>
        </div>
        <p v-if="addBrokerSuccess" class="mt-60 my-success">券商信息提交成功，可以使用一刀智投！返回<router-link to="user-account">个人中心</router-link></p>
      </div>
      <!-- </b-form> -->
    </div>
  </div>
</template>

<script>
import '../../assets/style/common.scss'
import { mapState } from 'vuex'
import { required, minLength, helpers } from 'vuelidate/lib/validators'
const isPhoneNumber = helpers.regex('isPhoneNumber', /^1(3|4|5|7|8)\d{9}$/)

export default {
  data() {
    return {
      phoneNumber: '',
      validtionCode: '',
      borker: null,
      borkerAccount: '',
      borkerPassword: '',
      tradePassword: '',

      borkers: [
        { id: null, name: '选择券商' }
      ],
      name: '',
      age: 0,

      isRegistered: false,
      addBrokerSuccess: false,
      brokerInfo: null
    }
  },

  validations: {
    phoneNumber: {
      required,
      isPhoneNumber
    },
    validtionCode: {
      required,
      minLength: minLength(6)
    },
    username: {
      required,
      minLength: minLength(2)
    },
    borker: {
      required
    },
    password: {
      required,
      minLength: minLength(8)
    },
    passwordAgain: {
      required,
      minLength: minLength(8)
    },
    borkerAccount: {
      required,
      minLength: minLength(6)
    },
    borkerPassword: {
      required,
      minLength: minLength(6)
    },
    tradePassword: {
      required,
      minLength: minLength(6)
    }

  },

  computed: {
    ...mapState({
      loginStatus: state => state.login.loginStatus,
      userName: state => state.login.userName,
      brokerInfoData: state => state.login.brokerInfoData
    })
  },

  mounted() {
    this.getContainerHeight()
    this.fetchBrokerInfo()
    this.http.get('/v3/securities').then(
      res => {
        console.log(res)
        this.borkers = this.borkers.concat(res.data.data)
      }
    )
  },

  methods: {
    fetchBrokerInfo: function() {
      if (this.brokerInfoData !== null) {
        this.phoneNumber = this.brokerInfoData.phonenumber
        this.broker = this.brokerInfoData.securities
        this.brokerAccount = this.brokerInfoData.securitiesUsername
        this.brokerPassword = this.brokerInfoData.securitiesPassword
        this.tradePassword = this.brokerInfoData.securitiesCpass
      }
    },

    getContainerHeight: function() {
      const screenHeight = document.getElementById('screenSize1')
      const pageHeight = window.innerHeight
      console.log('window.innerHeight', window.height)
      screenHeight.style.height = pageHeight + 'px'
    },

    pullBrokerInfo: function(phoneNumber, username, borker, password, borkerAccount, tradePassword, borkerPassword) {
      // e.preventDefault()
      this.$v.borker.$touch()
      this.$v.phoneNumber.$touch()
      this.$v.tradePassword.$touch()
      this.$v.borkerAccount.$touch()
      const isBorker = this.$v.borker.$invalid
      const isTradePassword = this.$v.tradePassword.$invalid
      const isBorkerAccount = this.$v.borkerAccount.$invalid
      const data = {
        phonenumber: phoneNumber,
        username: username,
        securities: borker,
        password: password,
        securitiesCpass: tradePassword,
        securitiesPassword: borkerPassword,
        securitiesUsername: borkerAccount,
        nativeCode: 'imAinvestcnabc',
        ua: 'chrome'
      }
      if (phoneNumber && !isBorker && !isBorkerAccount && !isTradePassword) {
        this.http.post('/v3/applyyidao', data).then(res => {
          console.log('pull is ', res)
          if (res.data.code === 20000) {
            this.addBrokerSuccess = true
          } else {
            alert('提交错误！')
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// .screen-size {
//   height: 100%;
// }
.dirty {
  border-color: #5A5;
  background: #EFE;
}

.dirty:focus {
  outline-color: #8E8;
}

.input-group-error {

  .input-error{
  border-color: #f57f6c;
  }

  .error{
    display: block !important;
  }
}

.error {
      font-size: 0.75rem;
    line-height: 1;
    display: none;
    margin-left: 14px;
    margin-top: 0.6875rem;
    display: none;
    color: #f57f6c;
}

.my-error {
      font-size: 0.75rem;
    line-height: 1;
    margin-left: 14px;
    margin-top: 0.6875rem;
    color: #f57f6c;
}

.my-success {
      font-size: 0.75rem;
    line-height: 1;
    margin-left: 14px;
    margin-top: 1.6875rem;
    color: #666;
}

.error:focus {
  outline-color: #F99;
}

.login-form {
  width: 450px;
  padding: 40px 60px;
  border-radius: 5px;

  img {
    width: 50%;
    text-align: center;
  }
}

button {
  width: 100%;
  height: 38px;
  border: 1px solid #ff3333ff;
  background-color: #fff;
  color: #ff3333ff;
  border-radius: 4px;
  font-size: 20px;
  font-family: PingFang-SC-Medium;
  font-weight: 500;
}

button:hover {
  border: 0;
  background: rgba(255, 51, 51, 1);
  color: #fff;
}

.validation-input{
  position: relative;
}

.validation-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 10px;
  color: #666;
  cursor: pointer;
}

.validation-button:hover{
  color: #eb483f;
}

</style>
